import zh from 'date-fns/locale/zh-TW'
import datePicker from 'vue3-datepicker'
import select from '@/components/elements/select'
export default {
    components: { 
        datePicker, 
        'app-select': select
    },
    data() {
        return {
            form: { username:'', password:'' },
            ready: false,
            activePage: 1,
            totalPage: 4,
            district: [],
            locale: zh,
            upperLimit: null,
            lowerLimit: null,
            error: '',
            submit_error: false,
            submitting: false
        }
    },
    mounted() {
        this.$root.setQuestionnaire()

        this.upperLimit = this.getLimitDate('today')
        this.lowerLimit = this.getLimitDate({ year:-20 })
        
        const params = new FormData()
        params.append('token', this.Auth.getToken())

        this.$axios
            .post(process.env.VUE_APP_API + 'check-questionnaire' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
            .then(response => {
                let r = response.data
                if (r.success === true) {
                    this.$root.setCompleteQuestionnaire()
                    this.$router.push('/course/')
                } else {
                    this.$axios
                        .post(process.env.VUE_APP_API + 'get-district' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                        .then(response => {
                            let r = response.data
                            if (r.success === true) {
                                this.district = r.data
                                this.ready = true
                            }
                        })
                }
            })

    },
    emits:[ 'swapInner' ],
    methods: {
        getLimitDate(type) {
            let d = null
            if (typeof type === 'string') {
                switch (type)
                {
                    case 'today' :
                        d = new Date()
                        break
                }
            } else if (typeof type === 'object') {
                for(let i in type)
                {
                    switch (i)
                    {
                        case 'year' :
                            d = new Date()
                            d.setYear(d.getFullYear() + type[i])
                            break
                    }
                }
            }
            return d
        },
        validField () {
            var valid = true
            this.error = ''

            let check = {
                'page1' : [
                    'student_chinese_name',
                    'student_english_name',
                    'student_dob',
                    'district_id',
                    'chinese_address',
                    'english_address'
                ],
                'page2' : [
                    'school_chinese_name',
                    'school_english_name',
                    'school_type'
                ],
                'page3' : [
                    'family_member',
                    'father_work',
                    'father_phone',
                    'mother_work',
                    'mother_phone',
                    'activity'
                ],
                'page4' : [
                    'school_1',
                    'school_2',
                    'school_3',
                    'parent_is_graduate',
                    'parent_is_graduate_yes_name',
                    'sibling_is_graduate',
                    'sibling_is_graduate_yes_name',
                    'agree_privacy'
                ]
            }

            for (let i in check['page'+this.activePage]) {
                let field = check['page'+this.activePage][i]

                let checked = true
                if (field === 'parent_is_graduate_yes_name') {
                    if (this.form['parent_is_graduate'] !== '是') checked = false
                }

                if (field === 'sibling_is_graduate_yes_name') {
                    if (this.form['sibling_is_graduate'] !== '是') checked = false
                }

                if (checked) {
                    if (this.form[field] === '' || this.form[field] === undefined) {
                        valid = false
                        this.error = field

                        if (this.$refs[field]) this.$refs[field].focus()

                        break
                    }
                }
            }

            return valid
        },
        next () {
            if (this.validField()) {
                this.activePage ++
            }
        },
        prev () {
            this.activePage --
        },
        focus() {
            let o = this.$refs['date-picker']
            var pt = o.offsetParent.offsetTop
            let scrollTop = document.documentElement.scrollTop || window.pageYOfset ||document.body.scrollTop;
            let windowHeight = window.innerHeight

            let d = o.getElementsByClassName('v3dp__popout')
            for(let i in d) {
                if (d[i].style) {
                    d[i].style.top = 'inherit'
                }
            }
            let mq = window.matchMedia('(max-width: 700px)')
            if(!mq.matches) {
                if (pt - scrollTop > windowHeight / 2) {
                    for(let i in d) {
                        if (d[i].style) {
                            let h = d[i].clientHeight
                            d[i].style.top = (h*-1) + 'px'
                        }
                    }
                }
            } else {
                for(let i in d) {
                    if (d[i].style) {
                        d[i].style.top = '0px'
                    }
                }
            }
        },
        submit() {
            if (this.validField()) {
                this.submitting = true
                const params = new FormData()
                for(let i in this.form)
                {
                    switch (i) {
                        default : {
                            params.append(i, this.form[i])
                            break
                        }
                        case 'student_dob' : {
                            let d = this.form[i]
                            let date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
                            params.append(i, date)
                            break
                        }
                    }
                }
                params.append('token', this.Auth.getToken())
                console.log('submit')
                this.$axios
                    .post(process.env.VUE_APP_API + 'questionnaire-db' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                    .then(response => {
                        let r = response.data
                        if (r.success === true)
                        {
                            this.$root.setCompleteQuestionnaire()
                            this.$router.push('/course/')
                        } else {
                            if (r.error === 'already_done') {
                                this.submit_error = true
                            }
                        }
                    })
            }
        },
        updateDistrict(value)
        {
            this.form.district_id = value
        }
    }
}
